import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import apiClient from '../../../shared-components/utils/ApiClient'
import CreatePostModal from './CreatePostModal'
import { useRouter } from 'next/router'
import InfiniteScroll from 'react-infinite-scroll-component'
import { closeChatModal, openCreatePostModal } from '../../../actions/modals'
import PostsMessage from '../posts/PostsMessage'
import EventSection from '../EventSection'
import { Add, KeyboardArrowDown } from '@mui/icons-material'
import AddRsvpMessageBox from '../posts/AddRsvpMessageBox'
import { setPostToAdd } from '../../../actions/rsvp'
import { useUser } from 'utils/userFunctions'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  progress: {
    margin: theme.spacing(2),
  },
  rsvpMessageBox: {
    marginBottom: theme.spacing(2),
  },
  emptyContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyAction: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addPostButton: {
    color: theme.palette.primary.dark,
  },
  noPaddingBottom: {
    paddingBottom: theme.spacing(0.5),
  },
  advancedBoxButton: {
    padding: theme.spacing('6px', 0),
  },
}))

const POSTS_SIZE = 5

const PostsSection = ({ desktopMode, items, setItems }) => {
  const router = useRouter()
  const { classes } = useStyles()
  const query = router.query
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [loadingInfo, setLoadingInfo] = useState({
    loading: false,
    hasMore: true,
    currentPage: 0,
  })

  const allOpen = useSelector((state) => state.modals.open.chat)
  const [shouldFocusBox, setShouldFocusBox] = useState(false)
  const [filter, setFilter] = useState(null)
  const postToAdd = useSelector((state) => state.rsvp.postToAdd)
  const [titleAnchor, setTitleAnchor] = useState(null)
  const { user } = useUser()

  const currentEventRef = React.useRef(null)
  const currentRequestRef = React.useRef(null)
  const abortControllerRef = React.useRef(null)

  useEffect(() => {
    if (event?.id) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      currentRequestRef.current = null
      currentEventRef.current = event.id
      setFilter(null)
      setLoadingInfo({
        loading: false,
        hasMore: true,
        currentPage: 0,
      })
      setItems([])
    }

    return () => {
      currentRequestRef.current = null
      currentEventRef.current = null
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      if (setItems && typeof setItems === 'function') setItems([])
    }
  }, [event?.id])

  const showRsvpPostBox =
    ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(event.myGuest?.status) &&
    (event.myGuest?.name || event?.myGuest.user) &&
    !event.myGuest?.hasRsvpPosted

  const loadMore = async () => {
    if (
      !loadingInfo.hasMore ||
      loadingInfo.loading ||
      !event?.id ||
      event.id !== currentEventRef.current
    )
      return

    const requestKey = `${event.id}-${loadingInfo.currentPage}`
    if (currentRequestRef.current === requestKey) return
    currentRequestRef.current = requestKey

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    const requestEventId = event.id

    setLoadingInfo((prev) => ({
      ...prev,
      loading: true,
    }))

    try {
      if (requestEventId !== currentEventRef.current) return

      const response = await apiClient.event.getPosts(
        requestEventId,
        event.code,
        loadingInfo.currentPage,
        POSTS_SIZE,
        event.code,
        filter,
        { signal: abortControllerRef.current.signal },
      )

      if (requestEventId === currentEventRef.current) {
        if (response && response.length > 0) {
          setItems((prev) =>
            loadingInfo.currentPage === 0 ? response : [...prev, ...response],
          )
          setLoadingInfo((prev) => ({
            loading: false,
            hasMore: response.length === POSTS_SIZE,
            currentPage: prev.currentPage + 1,
          }))
        } else {
          setLoadingInfo({
            loading: false,
            hasMore: false,
            currentPage: loadingInfo.currentPage,
          })
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') return

      if (requestEventId === currentEventRef.current) {
        setLoadingInfo((prev) => ({
          ...prev,
          loading: false,
          hasMore: false,
        }))
      }
    } finally {
      if (requestEventId === currentEventRef.current) {
        currentRequestRef.current = null
      }
    }
  }

  useEffect(() => {
    if (!loadingInfo.loading && loadingInfo.hasMore && event?.id) {
      loadMore()
    }
  }, [loadingInfo.currentPage, event?.id])

  useEffect(() => {
    if (query.chat) {
      dispatch(openCreatePostModal())
      // setAllOpen(true)
    }
  }, [query])

  const onSend = (message) => {
    if (items.some((m) => m.id === message.id)) {
      setItems((prev) => prev.map((m) => (m.id === message.id ? message : m)))
    } else {
      setItems((prev) => [message, ...prev])
    }
  }

  const changeMessage = (id, changes) => {
    setItems((prev) =>
      prev.map((p) => (p.id === id ? { ...p, ...changes } : p)),
    )
  }

  const onAllClose = () => {
    dispatch(closeChatModal())
    setShouldFocusBox(false)
  }

  useEffect(() => {
    if (postToAdd != null) {
      onSend(postToAdd)
      dispatch(setPostToAdd(null))
    }
  }, [postToAdd, event.myGuest])

  const showAddPostButton =
    ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(event.myGuest?.status) &&
    !event.host

  const onDelete = (id) => {
    setItems(items.filter((item) => item.id !== id))
  }

  if (!items && filter !== 'HOST') return null

  if (
    // event.creationType === 'EXTERNAL' ||
    event.hasJoinOptions === false ||
    (items.length === 0 && !showAddPostButton && !event.host)
  ) {
    return (
      <>
        <CreatePostModal
          open={allOpen}
          onClose={onAllClose}
          onSendMessage={onSend}
          onChangeMessage={changeMessage}
          shouldFocusBox={shouldFocusBox}
          onDelete={onDelete}
        />
      </>
    )
  }

  return (
    <EventSection
      title={t('activity')}
      desktopMode={desktopMode}
      arrowRight={false}
      headerClassName={showAddPostButton ? classes.noPaddingBottom : undefined}
      customContent={
        showAddPostButton ? (
          <Button
            className={classes.addPostButton}
            startIcon={<Add />}
            color='primary'
            onClick={() => {
              dispatch(openCreatePostModal())
            }}
          >
            {t('comment')}
          </Button>
        ) : null
      }
      customTitle={
        <>
          <Button
            classes={{
              root: classes.advancedBoxButton,
            }}
            onClick={(e) => setTitleAnchor(e.currentTarget)}
          >
            {filter === 'HOST' ? t('postsHostsOnly') : t('activity')}
            <KeyboardArrowDown />
          </Button>
          <Menu
            id='join-options-menu'
            anchorEl={titleAnchor}
            keepMounted
            open={!!titleAnchor}
            onClose={() => setTitleAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              list: classes.menuList,
            }}
          >
            <MenuItem
              onClick={() => {
                setFilter(null)
                setTitleAnchor(null)
                setLoadingInfo({
                  loading: false,
                  hasMore: true,
                  currentPage: 0,
                })
                setItems([])
              }}
            >
              {t('all')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilter('HOST')
                setTitleAnchor(null)
                setLoadingInfo({
                  loading: false,
                  hasMore: true,
                  currentPage: 0,
                })
                setItems([])
              }}
            >
              {t('hostsOnly')}
            </MenuItem>
          </Menu>
        </>
      }
    >
      {showRsvpPostBox && (
        <AddRsvpMessageBox className={classes.rsvpMessageBox} onSend={onSend} />
      )}

      <div id='scrollableDiv' className={classes.scrollContainer}>
        {!loadingInfo.hasMore && items.length === 0 && !showRsvpPostBox && (
          <div className={classes.emptyContainer}>
            <Typography variant='subtitle1'>{t('postsEmptyTitle')}</Typography>
            {['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(
              event.myGuest?.status,
            ) && (
              <Typography variant='body2'>
                <Trans
                  t={t}
                  i18nKey='postsEmptyCaption'
                  components={{
                    bold: (
                      <span
                        className={classes.emptyAction}
                        onClick={() => dispatch(openCreatePostModal())}
                      />
                    ),
                  }}
                />
              </Typography>
            )}
          </div>
        )}

        <InfiniteScroll
          dataLength={items.length}
          next={loadMore}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            gap: 24,
          }}
          hasMore={loadingInfo.hasMore}
          loader={<CircularProgress className={classes.progress} />}
          scrollThreshold={0.6}
        >
          {items
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t?.id === item?.id),
            )
            .filter((item) => item?.poster?.model?.name?.length > 0)
            .map((item) => (
              <PostsMessage
                key={item.id}
                msg={item}
                onDelete={() => onDelete(item.id)}
                setPost={(post) => {
                  setItems(
                    items.map((item) => (item.id === post.id ? post : item)),
                  )
                }}
              />
            ))}
        </InfiniteScroll>
      </div>

      <CreatePostModal
        open={allOpen}
        onClose={onAllClose}
        onSendMessage={onSend}
        onChangeMessage={changeMessage}
        shouldFocusBox={shouldFocusBox}
        onDelete={onDelete}
      />
    </EventSection>
  )
}

export default PostsSection
