import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { InputBase, SvgIcon, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Add, Info, Link, Remove } from '@mui/icons-material'
import { mdiLink } from '@mdi/js'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCurrentEvent } from 'selectors/event'
import {
  ApprovalBadge,
  AvailableFromBadge,
  AvailableUntilBadge,
  HiddenBadge,
  NearCapacityBadge,
} from '../EventCards/Badges'
import {
  ExternalMessageInfo,
  ExternalUrlInfo,
} from 'components/modals/JoiningInfoModal'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '8px',
    display: 'flex',
    gap: theme.spacing(1.4),
    width: '100%',
    background:
      theme.extras.name === 'custom' && theme.palette.mode === 'dark'
        ? theme.palette.background.default
        : theme.palette.background.paper,
  },
  disabled: {
    opacity: 0.5,
  },
  selectedRoot: {
    borderColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    width: '100%',
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    justifyContent: 'space-between',
  },
  bottomEnd: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    gap: 8,
  },
  soldOut: {
    cursor: 'default !important',
    opacity: 0.5,
  },
  right: {
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'flex-start',
    '-webkit-user-select': 'none' /* Safari */,
    '-ms-user-select': 'none' /* IE 10 and IE 11 */,
    'user-select': 'none',
  },
  name: {
    fontWeight: 500,
    display: 'flex',
    wordBreak: 'break-word',
  },
  removeButton: {
    cursor: 'default',
    background: theme.palette.grey[200],
    borderRadius: '100%',
    color: theme.palette.text.tertiary,
    width: 20,
    height: 20,
  },
  addButton: {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    borderRadius: '100%',
    color: 'white',
    width: 20,
    height: 20,
  },
  soldOutAdd: {
    background: theme.palette.grey[200],
    color: theme.palette.text.tertiary,
    cursor: 'default',
  },
  shareIcon: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary[600],
    },
  },
  priceInput: {
    borderRadius: 8,
    background: theme.palette.grey[200],
    padding: theme.spacing(0, 1),
    marginLeft: 'auto',
    width: 88,
  },
  clickable: {
    cursor: 'pointer',
  },
  number: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.09px',
    textAlign: 'right',
  },
  namePriceAmountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  namePriceContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 20,
  },
  disabled: {
    color: theme.palette.text.tertiary,
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  infoIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    color: theme.palette.text.tertiary,
  },
  infoContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  eventixLogo: {
    width: 13.5,
    height: 12,
    marginRight: theme.spacing(1),
    marginTop: 2,
  },
}))

const TicketsStepItem = ({
  totalCount,
  onSelect,
  selected,
  ticket,
  quantitySelected,
  totalSelected,
  onChange,
  onPriceChange,
  disabled,
}) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const unavailable =
    ticket.quantityAvailableForYou === 0 || ticket.available === false
  const [linkSheetOpen, setLinkSheetOpen] = useState(false)
  const [ownPrice, setOwnPrice] = useState('')
  const event = useSelector(getCurrentEvent)
  const [infoOpen, setInfoOpen] = useState(false)
  const formatter = new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: ticket.currency ?? 'EUR',
  })

  const text = () => {
    const priceText = formatter.format(ticket.price / 100)

    // Calculate fees if they exist
    const getFeeText = () => {
      if (
        !ticket.fees ||
        (!ticket.fees.applied.fixed && !ticket.fees.applied.varyingPromille) ||
        (ticket.fees.applied.fixed === 0 &&
          ticket.fees.applied.varyingPromille === 0)
      ) {
        return ''
      }

      const fixedFee = ticket.fees.applied.fixed / 100
      const varyingFee = ticket.fees.applied.varying / 100
      const totalFee = fixedFee + varyingFee

      return ` + ${formatter.format(totalFee)} fee`
    }

    const feeText = getFeeText()

    if ((ticket.quantityAvailableForYou ?? 999999) < 1 && !selected) {
      if (ticket.price) {
        return `${priceText} - ${t('soldOut')}`
      }
      return t('soldOut')
    }
    if (ticket.availableFrom && dayjs(ticket.availableFrom).isAfter(dayjs())) {
      if (ticket.price) {
        return `${priceText}${feeText}`
      }
      return ''
    } else if (ticket.available === false && !selected) {
      if (ticket.price) {
        return `${priceText} - ${t('soldOut')}`
      }
      return t('soldOut')
    } else if (ticket.chooseYourOwnPrice) {
      return t('newCreation.ticketsAdvanced.nameYourOwnPrice')
    } else if (ticket.price) {
      if (ticket.availableFrom && ticket.availableFrom > Date()) {
        return `${priceText} - ${t('availableFromDate', {
          date: dayjs(ticket.availableFrom).format('D MMM HH:mm'),
        })}`
      }
      return `${priceText}${feeText}`
    }
    return t('free')
  }

  const linkClicked = () => {
    setLinkSheetOpen(true)
  }

  const onCopyClicked = (e) => {
    e.stopPropagation()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(ticket.hiddenLink)
      dispatch(setSnackbar('info', t('shared:linkCopiedToClipboard')))
    } else {
      dispatch(setSnackbar('error', 'Failed to copy'))
    }
  }

  const onLocalPriceChange = (newValue) => {
    const newNumber = parseFloat(newValue.replace(',', '.'))
    const test = Number.isFinite(newNumber)
    if (test || !newValue) {
      setOwnPrice(newValue)
      onPriceChange(parseInt(`${newNumber * 100}`))
    }
  }

  const onPriceBlur = () => {
    if (ownPrice && ownPrice !== '') {
      setOwnPrice(parseFloat(ownPrice.replace(',', '.')).toFixed(2))
    }
  }

  const plusClicked = () => {
    onChange(quantitySelected + (ticket.incrementBy ?? 1))
  }

  const minusClicked = () => {
    onChange(quantitySelected - (ticket.incrementBy ?? 1))
  }

  const disablePlus =
    quantitySelected + (ticket.incrementBy ?? 1) >
      (ticket.quantityAvailableForYou ?? 9999999) ||
    quantitySelected + (ticket.incrementBy ?? 1) >
      (ticket.maxAmountPerGuest ?? 9999999) ||
    unavailable ||
    totalSelected >= (event.totalTicketQuantityAvailable ?? 9999999)

  const disableMinus =
    !selected ||
    quantitySelected <= (ticket.incrementBy ?? 1) ||
    quantitySelected <= (ticket.minAmountPerOrder ?? 1)

  const selectable = totalCount > 1

  return (
    <div
      onClick={onSelect}
      className={cx(
        classes.root,
        unavailable && classes.soldOut,
        selected && !disabled && classes.selectedRoot,
        disabled && classes.disabled,
        (selectable || disabled) && classes.clickable,
      )}
    >
      <div className={classes.container}>
        <div className={classes.namePriceAmountRow}>
          <div className={classes.namePriceContainer}>
            <Typography variant='body2' className={classes.name}>
              {ticket.idInfo?.source === 'EVENTIX' && (
                <img
                  src='/images/eventixLogo.svg'
                  className={classes.eventixLogo}
                />
              )}
              {ticket.name}
              {ticket.hiddenLink && (
                <SvgIcon onClick={onCopyClicked} className={classes.shareIcon}>
                  <path d={mdiLink} />
                </SvgIcon>
              )}
            </Typography>
            {ticket.type === 'REGISTRATION_INFO' && (
              <div className={classes.infoContainer}>
                <ExternalMessageInfo
                  open={infoOpen}
                  onClose={() => setInfoOpen(false)}
                />
                <ExternalUrlInfo
                  open={linkSheetOpen}
                  onClose={() => setLinkSheetOpen(false)}
                />
                {ticket.hasText && (
                  <Tooltip title={t('externalInstructions')}>
                    <Info
                      onClick={(e) => {
                        e.stopPropagation()
                        setInfoOpen(true)
                      }}
                      className={classes.infoIcon}
                    />
                  </Tooltip>
                )}
                {ticket.hasUrl && (
                  <Tooltip title={t('externalRegistrationDescription')}>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation()
                        setLinkSheetOpen(true)
                      }}
                      className={classes.infoIcon}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {(ticket.maxAmountPerGuest ?? 9999999) > 1 && selected && (
            <div className={classes.right}>
              <Remove
                className={cx(
                  classes.removeButton,
                  !disableMinus && classes.addButton,
                )}
                onClick={disableMinus ? undefined : minusClicked}
              />
              <Typography
                color={selected ? 'textPrimary' : 'textSecondary'}
                className={classes.number}
              >
                {quantitySelected}
              </Typography>
              <Add
                className={cx(
                  classes.addButton,
                  (unavailable || !selected) && classes.soldOutAdd,
                  disablePlus && classes.soldOutAdd,
                )}
                onClick={() => (disablePlus ? {} : plusClicked())}
              />
            </div>
          )}
        </div>

        {(ticket.type === 'TICKET' || ticket.type === 'EXTERNAL_TICKET') && (
          <Typography variant='body2' color='textSecondary'>
            {text()}
          </Typography>
        )}
        {(ticket.available && ticket.availableTo) ||
        (!ticket.available &&
          ticket.availableFrom &&
          dayjs(ticket.availableFrom).isAfter(dayjs())) ||
        ticket.hidden ||
        ticket.nearCapacity ||
        ticket.approvalRequired ? (
          <div className={classes.badgeContainer}>
            {ticket.hidden && <HiddenBadge joinOption={ticket} />}
            {ticket.approvalRequired && <ApprovalBadge />}
            {ticket.nearCapacity && <NearCapacityBadge />}
            {ticket.available &&
              ticket.availableTo &&
              dayjs(ticket.availableTo).isBefore(dayjs(event.startDate)) && (
                <AvailableUntilBadge joinOption={ticket} />
              )}
            {!ticket.available &&
              ticket.availableFrom &&
              dayjs(ticket.availableFrom).isAfter(dayjs()) && (
                <AvailableFromBadge joinOption={ticket} />
              )}
          </div>
        ) : null}
        {ticket.approvalRequired &&
          Boolean(ticket.price && ticket.price > 0) && (
            <Typography variant='caption' color='textSecondary'>
              {t('paymentAfterAccept')}
            </Typography>
          )}
        {ticket.chooseYourOwnPrice && quantitySelected > 0 && selected && (
          <div className={classes.bottom}>
            <Typography variant='body2' style={{ fontWeight: 500 }}>
              {t('pricePerTicket')}
            </Typography>
            <div className={classes.bottomEnd}>
              <InputBase
                id='price'
                placeholder={formatter.format(ticket.price / 100).substring(1)}
                startAdornment={<Typography variant='caption'>€</Typography>}
                className={cx(classes.priceInput)}
                value={ownPrice}
                onChange={(e) => onLocalPriceChange(e.target.value)}
                margin='none'
                inputMode='numeric'
                // type='number'
                onBlur={onPriceBlur}
                inputProps={{
                  min: 0,
                  style: { textAlign: 'end' },
                }}
              />
              {ownPrice && ownPrice < 1 && ownPrice > 0 && (
                <Typography variant='caption' color='textSecondary'>
                  {t('selectAbove1Euro')}
                </Typography>
              )}
              {ownPrice > 1 && ticket.fees?.applied?.fixed > 0 && (
                <Typography variant='caption' color='textSecondary'>
                  {'+ ' +
                    formatter.format(ticket.fees.applied.fixed / 100) +
                    ' service fee'}
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TicketsStepItem
